import GA77999 from '../assets/Captchas/GA77999.png';
import GAWIN88 from '../assets/Captchas/GAWIN88.png';
import GA6799 from '../assets/Captchas/GA6799.png';
import SV388 from '../assets/Captchas/SV388.png';

const captchas = [
    { image: GA77999, value: "ga77999" },
    { image: GAWIN88, value: "gawin88" },
    { image: GA6799, value: "ga6799" },
    { image: SV388, value: "sv388" }
];

export default captchas;