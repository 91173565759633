import React from 'react'
import styles from './Playnow.module.css'	
import cockFight from '../../assets/thidParty/cockfighting-_1_.webp'

const Playnow = () => {
    const userToken  = localStorage?.getItem('auth_token');
    const redirectToWeb = () => {
        
        const safeEncodedToken = encodeURIComponent(btoa(userToken));

        const gameUrl = `https://gawin88.top/login/third_party?token=${safeEncodedToken}`;

        // Redirect to the game's website with the encoded token
        window.top.location.href = gameUrl;
    };


  return (
    <div>
          <div className={styles.playNowWrapper}>
            <div className={styles.divWrapper}>
                  <img src={cockFight} alt='image123'  />
            </div>
              <button onClick={redirectToWeb} className={styles.btnStyle}>
                  {/* <img className={styles.elementStyle} src={Element1} alt='img1'/>
                  <span >Chơi Ngay</span>
                  <img className={styles.elementStyle} src={Element2} alt='img2' /> */}
              </button>

          </div>
    </div>
  )
}

export default Playnow
