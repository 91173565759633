import axios from "axios";

const BaseUrl = axios.create({
  // Staging gasv388 Test
  // baseURL: "https://staging.gasv388.net/api",

   // Master testing
  //  baseURL: "https://master.gasv388.net/api",

  // ga6789info [gawin] base URL Live
  baseURL: "https://bogaproject2.com/api",

  //masterURL 
  // baseURL: "https://master.ga6789.net/api",
});

export default BaseUrl;
